import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { post } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';

import Button from 'components/CustomButtons/Button';
import GridItem from 'components/Grid/GridItem.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import FormTextInput from 'components/FormTextInput/FormTextInput';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import { AddCircle } from '@material-ui/icons';

import Task from './Task';
import DEFAULT_TASK from './DefaultTask.const';
import CardWrapper from 'components/Card/CardWrapper';
import { toast } from 'react-toastify';

const NewTasksGenerator = ({ classes, onAfterSubmit }) => {
  const { t } = useTranslation();

  const [note, setNote] = useState('');
  const [tasks, setTasks] = useState([DEFAULT_TASK]);
  const [isGenerating, setIsGenerating] = useState(false);

  const initializeForm = () => {
    setTasks([DEFAULT_TASK]);
    setIsGenerating(false);
  };

  const handleAddTask = () => {
    setTasks([...tasks, DEFAULT_TASK]);
  };

  const handleRemoveTask = taskToRemoveIndex => {
    setTasks(tasks.filter((task, index) => index !== taskToRemoveIndex));
  };

  const handleChange = ({ value, label, index }) => {
    let tmpTask = { ...tasks[index] };

    tmpTask = { ...tmpTask, [label]: value };

    setTasks(
      tasks.map((task, taskIndex) => {
        if (taskIndex === index) {
          return tmpTask;
        }
        return task;
      })
    );
  };

  const handleSubmit = async () => {
    setIsGenerating(true);
    try {
      const params = tasks.map(
        ({
          selectedBrands,
          selectedSubBrands,
          dateTo,
          dateFrom,
          multiplier,
          includeSubscriptions,
        }) => {
          const filteredSelectedBrands = (selectedBrands ?? [])
            .map(({ value }) => value)
            .filter(value => value !== '*');
          const filteredSelectedSubBrands = (selectedSubBrands ?? [])
            .map(({ value }) => value)
            .filter(value => value !== '*');

          return {
            _valid:
              filteredSelectedSubBrands.length + filteredSelectedBrands.length >
              0,
            brands: filteredSelectedBrands,
            subBrands: filteredSelectedSubBrands,
            dateTo,
            dateFrom,
            multiplier: parseFloat(multiplier),
            includeSubscriptions,
          };
        }
      );

      if (params.find(el => el.multiplier === '')) {
        toast.error(
          `${t('common.multiplier')}, ${t('formValidation.required')}`
        );

        setIsGenerating(false);
        return;
      }

      if (params.filter(el => el._valid).length !== params.length) {
        toast.error(
          t(
            '$*cateringsFlow.tasksGeneratorPage.newTasksGenerator.needToSelectBrands'
          )
        );

        setIsGenerating(false);
        return;
      }

      await post(
        '/task-lists',
        { params, note },
        {
          params: { selectInBrands: [].concat(...params.map(el => el.brands)) },
        }
      );

      onAfterSubmit();
      initializeForm();
      toast.success(
        t(
          '$*cateringsFlow.tasksGeneratorPage.newTasksGenerator.tasksGeneratedSuccessfully'
        )
      );
    } catch (error) {
      toast.error(t('http.clientBadRequest'));
      setIsGenerating(false);
    }
  };

  return (
    <>
      <CardWrapper
        title={t('$*tasksGeneratorPage.generateTasks', '$$Wygeneruj zadania')}
        style={{ marginTop: '20px' }}
      >
        <CardBody>
          <div>
            {tasks.map((task, index) => (
              <Task
                canBeRemoved={index > 0}
                removeTask={() => handleRemoveTask(index)}
                handleChange={(value, label) =>
                  handleChange({ value, label, index })
                }
                {...task}
              />
            ))}
          </div>
          <GridContainer justify={'space-between'}>
            <GridItem>
              <Button onClick={handleAddTask} color={'default'} round justIcon>
                <AddCircle />
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem sm={12}>
              <FormControl fullWidth>
                <FormTextInput
                  className="input-text"
                  label={t('common.notes', 'Notatki')}
                  classes={classes}
                  name="note"
                  value={note}
                  handleChange={e => setNote(e.target.value)}
                  rowsMax={20}
                  inputSize={12}
                  maxLength={500}
                  multiline={true}
                />
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer justify={'flex-end'}>
            <GridItem>
              <Button
                onClick={handleSubmit}
                color={'success'}
                round
                disabled={isGenerating}
              >
                {t('$*tasksGeneratorPage.generateTasks', '$$Wygeneruj zadania')}
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withStyles(combinedStyles)(NewTasksGenerator);
