import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { isGranted } from 'helpers/helpers';
import {
  ROLE_CREATE_TASKS_GENERATOR,
  ROLE_SHOW_TASKS_GENERATOR,
  ROLE_EDIT_TASKS_GENERATOR,
} from 'helpers/roles';

import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';

import VisibilityIcon from '@material-ui/icons/Visibility';
import GeneratedTasks from '../common/GeneratedTasks/GeneratedTasks';
import NewTaskGenerator from './NewTasksGenerator/NewTasksGenerator';
import TasksListDetailsModal from '../common/TasksListDetalsModal/TasksListDetailsModal';

import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import SweetAlert from 'react-bootstrap-sweetalert';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
import { Dialog } from '@material-ui/core';
import { put } from 'helpers/apiHelpers';
import { toast } from 'react-toastify';
const TasksGeneratorComponent = ({ classes }) => {
  const [selectedRow, setSelectedRow] = useState({});
  const [refreshGeneratedTasks, triggerRefreshGeneratedTasks] = useState(false);
  const [selectedRowStatusUpdate, setSelectedRowStatusUpdate] = useState(false);
  const { t } = useTranslation();

  const customActions = row => {
    return [
      {
        action: () => {
          setSelectedRowStatusUpdate(row);
        },
        icon: <img src="../images/updateFlow.svg" alt="" />,
        color: 'info',
        simple: true,
        round: false,
      },
      {
        action: () => {
          setSelectedRow(row);
        },
        icon: <VisibilityIcon />,
        color: 'info',
        simple: true,
        round: false,
      },
    ];
  };

  return (
    <>
      <GridContainer>
        {isGranted(ROLE_CREATE_TASKS_GENERATOR) && (
          <GridItem xs={12}>
            <NewTaskGenerator
              onAfterSubmit={() =>
                triggerRefreshGeneratedTasks(!refreshGeneratedTasks)
              }
            />
          </GridItem>
        )}
        {isGranted(ROLE_SHOW_TASKS_GENERATOR) && (
          <GridItem xs={12}>
            <GeneratedTasks
              refresh={refreshGeneratedTasks}
              canRemove={true}
              customActions={
                isGranted(ROLE_EDIT_TASKS_GENERATOR) && customActions
              }
              separatingConfigsParam={'fe=tasksGenerator'}
            />
          </GridItem>
        )}
      </GridContainer>
      <TasksListDetailsModal
        params={selectedRow?.params ?? []}
        comments={selectedRow?.note ?? ''}
        isOpened={!isEmpty(selectedRow)}
        closeModal={() => setSelectedRow({})}
      />
      <Dialog fullWidth={true} open={selectedRowStatusUpdate}>
        <SweetAlert
          warning
          title={t('$*cateringsFlow.action.modal.description.')}
          onConfirm={() => {
            put(`${selectedRowStatusUpdate['@id']}/refresh-amounts`, {});

            toast.success(t('$*cateringsFlow.action.updatedFlow.toast'));
            setSelectedRowStatusUpdate(false);
          }}
          onCancel={() => {
            setSelectedRowStatusUpdate(false);
          }}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          confirmBtnText={t('common.shared.yes')}
          cancelBtnText={t('common.shared.no')}
          showCancel
        />
      </Dialog>
    </>
  );
};

export default withStyles(sweetAlertStyle)(TasksGeneratorComponent);
