import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { combineStyles } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import Button from 'components/CustomButtons/Button';
import SelectInput from 'components/FormSelect/SelectInput';

import CATERINGS_FLOW_STATUSES from 'views/CateringsFlow/consts/cateringsFlowStatuses';
import CATERINGS_FLOW_STATUSES_LABELS from 'views/CateringsFlow/consts/cateringsFlowStatusesLabels';
import validTransition from 'views/CateringsFlow/consts/cateringsFlowStatusesValidation';
import STATUS_COLORS from 'views/CateringsFlow/consts/cateringsFlowStatusesColors';
import { toast } from 'react-toastify';

const statusPickerStyle = {
  statusPickerSelectBlack: {
    padding: '12px 0 7px',
    fontSize: '.75rem',
    fontWeight: '400',
    lineHeight: '1.42857',
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: '#000000',
    letterSpacing: '0',
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&[aria-owns] + input + svg': {
      transform: 'rotate(180deg)',
    },
    '& + input + svg': {
      transition: 'all 300ms linear',
    },
  },
  statusPickerSelectWhite: {
    padding: '12px 0 7px',
    fontSize: '.75rem',
    fontWeight: '400',
    lineHeight: '1.42857',
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    letterSpacing: '0',
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&[aria-owns] + input + svg': {
      transform: 'rotate(180deg)',
    },
    '& + input + svg': {
      transition: 'all 300ms linear',
    },
  },
  statusPickerSelectFormControl: {
    margin: '0 0 5px 0 !important',
    '& > div': {
      '&:before': {
        borderBottomWidth: '0 !important',
      },
      '&:after': {
        borderBottomWidth: '0 !important',
      },
    },
  },
  statusPickerIconBlack: {
    color: '#000000',
  },
  statusPickerIconWhite: {
    color: '#ffffff',
  },
};

const buildStatusObject = (status, t) => ({
  '@id': status,
  value: t(
    CATERINGS_FLOW_STATUSES_LABELS[status]?.translationKey ??
      CATERINGS_FLOW_STATUSES_LABELS[CATERINGS_FLOW_STATUSES.WAITING]
        ?.translationKey,
    CATERINGS_FLOW_STATUSES_LABELS[status]?.defaultValue ??
      CATERINGS_FLOW_STATUSES_LABELS[CATERINGS_FLOW_STATUSES.WAITING]
        ?.defaultValue
  ),
});

const StatusPicker = ({
  row,
  classes,
  initialStatus,
  isPickingEnabled = true,
  isSelectDisabled = false,
  onStatusChangeAction,
  excludeStatuses = [],
}) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(buildStatusObject(initialStatus, t));
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    getStatuses();
  }, []);

  useEffect(
    () => setStatus(buildStatusObject(initialStatus, t)),
    [initialStatus]
  );

  const getStatuses = async () => {
    try {
      const formattedStatuses = Object.values(CATERINGS_FLOW_STATUSES)
        .filter(
          el =>
            ![...excludeStatuses, CATERINGS_FLOW_STATUSES.CREATED].includes(el)
        )
        .map(statusCode => ({
          '@id': statusCode,
          value: t(
            CATERINGS_FLOW_STATUSES_LABELS[statusCode].translationKey,
            CATERINGS_FLOW_STATUSES_LABELS[statusCode].defaultValue
          ),
        }));

      setStatuses(formattedStatuses);
    } catch (e) {
      console.log(e);
    }
  };

  const changeStatus = async ({ target }) => {
    if (
      validTransition.some(
        transition =>
          transition.from.includes(status['@id']) &&
          transition.to.includes(target?.value)
      )
    ) {
      return;
    }

    try {
      await onStatusChangeAction({ row, newStatus: target?.value });

      setStatus({
        '@id': target.value,
        value: t(
          CATERINGS_FLOW_STATUSES_LABELS[target.value].translationKey,
          CATERINGS_FLOW_STATUSES_LABELS[target.value].defaultValue
        ),
      });
      toast.success(t('success.statusUpdated'));
    } catch (e) {
      console.log(e);
    }
  };

  const getCustomSelectClasses = () => {
    if (
      [
        CATERINGS_FLOW_STATUSES.IN_PROGRESS,
        CATERINGS_FLOW_STATUSES.DONE_WAITING,
        CATERINGS_FLOW_STATUSES.DONE,
      ].includes(status?.['@id'])
    ) {
      return {
        select: classes.statusPickerSelectWhite,
        icon: classes.statusPickerIconWhite,
      };
    }

    return {
      select: classes.statusPickerSelectBlack,
      icon: classes.statusPickerIconBlack,
    };
  };

  return (
    <div style={{ maxWidth: '140px' }}>
      {isPickingEnabled && status?.['@id'] !== 'CREATED' ? (
        <SelectInput
          classes={classes}
          value={status}
          mapBy={'value'}
          trackBy={'@id'}
          options={statuses}
          disabled={isSelectDisabled}
          handleChange={changeStatus}
          customSelectClasses={getCustomSelectClasses()}
          customFormControlClasses={{
            customSelectFormControl: classes.statusPickerSelectFormControl,
          }}
          customStyle={{
            backgroundColor:
              STATUS_COLORS[status?.['@id']]?.backgroundColor ?? '#ffffff',
            paddingLeft: '10px',
            borderRadius: '3px',
          }}
        />
      ) : (
        <Button
          style={{
            width: '100%',
            maxWidth: '130px',
            height: '36px',
            color: STATUS_COLORS[status?.['@id']]?.color ?? '#000000',
            cursor: 'auto',
            boxShadow: 'none',
            pointerEvents: 'none',
            backgroundColor:
              STATUS_COLORS[status?.['@id']]?.backgroundColor ?? '#ffffff',
            border: STATUS_COLORS[status?.['@id']]?.border ?? 'none',
          }}
        >
          {status?.value}
        </Button>
      )}
    </div>
  );
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  statusPickerStyle
);

export default withStyles(combinedStyles)(StatusPicker);
