import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import isEmpty from 'lodash/isEmpty';

import { get, put } from 'helpers/apiHelpers';
import { combineStyles, isGranted } from 'helpers/helpers';
import { ROLE_EDIT_BAGS_PACKHOUSE } from 'helpers/roles';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import EditIcon from '@material-ui/icons/Edit';
import withStyles from '@material-ui/core/styles/withStyles';
import VisibilityIcon from '@material-ui/icons/Visibility';

import CardBody from 'components/Card/CardBody.jsx';
import DataGrid from 'components/DataGrid/DataGrid';
import PdfDialog from 'components/PdfDialog/PdfDialog';

import columnConfig from './columnConfig';
import subTasksColumnConfig from './subTasksColumnConfig';
import SubTasksList from 'views/CateringsFlow/common/SubTasksList/SubTasksList';
import AmountOfDoneModal from 'views/CateringsFlow/BagsPackhouse/components/AmountOfDoneModal/AmountOfDoneModal';

import CATERINGS_FLOW_STATUSES from 'views/CateringsFlow/consts/cateringsFlowStatuses';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import GridItem from '../../../../../components/Grid/GridItem';
import GridContainer from '../../../../../components/Grid/GridContainer';
import STATUS_COLORS from 'views/CateringsFlow/consts/cateringsFlowStatusesColors';
import CardWrapper from 'components/Card/CardWrapper';

import { toast } from 'react-toastify';

const BagsPackhouseTasksList = ({ classes, tasksListId }) => {
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isPDFPreviewOpened, setIsPDFPreviewOpened] = useState(false);
  const [isAmountOfDoneModalOpened, setIsAmountOfDoneModalOpened] =
    useState(false);
  const [pendingStatusChange, setPendingStatusChange] = useState(null);
  const [showChoices, setShowChoices] = useState(true);
  const [showChoicesAndDiets, setShowChoicesAndDiets] = useState(true);

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await get('/employees/list', {
        partial: false,
        pagination: false,
        productionWorker: true,
      });
      setEmployees(
        (response?.['hydra:member'] ?? []).map(
          ({ firstName, lastName, '@id': iri, image }) => ({
            '@id': iri,
            value: `${firstName} ${lastName}`,
            contentUrl: image?.contentUrl,
          })
        )
      );
    } catch (error) {}
  };

  const onEmployeeChangeAction = async ({ row, employeeIri }) => {
    try {
      await put(`/tasks/${row?.id}/assign`, {
        assign: employeeIri,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const customActions = row => {
    return isGranted(ROLE_EDIT_BAGS_PACKHOUSE)
      ? [
          {
            action: () => {
              setSelectedItem(row);
              setIsAmountOfDoneModalOpened(true);
            },
            icon: <EditIcon />,
            color: 'warning',
            simple: true,
            round: false,
          },
          {
            action: () => {
              setSelectedItem(row);
              setIsPDFPreviewOpened(true);
            },
            icon: <VisibilityIcon />,
            color: 'info',
            simple: true,
            round: false,
          },
        ]
      : [
          {
            action: () => {
              setSelectedItem(row);
              setIsPDFPreviewOpened(true);
            },
            icon: <VisibilityIcon />,
            color: 'info',
            simple: true,
            round: false,
          },
        ];
  };

  const onStatusChangeAction = async ({ row, newStatus }) => {
    if (
      [
        CATERINGS_FLOW_STATUSES.DONE_WAITING,
        CATERINGS_FLOW_STATUSES.DONE,
      ].includes(row?.status) &&
      row?.status === newStatus
    ) {
      toast.warning(t('$*cateringsFlow.statusHasNotChanged'));
    }

    return new Promise(async (res, rej) => {
      const isAmountsModalRequired = [
        CATERINGS_FLOW_STATUSES.DONE_WAITING,
        CATERINGS_FLOW_STATUSES.DONE,
      ].includes(newStatus);

      if (isAmountsModalRequired) {
        setPendingStatusChange({
          url: `/tasks/${row?.id}/status`,
          payload: {
            status: newStatus,
            amountOfDone: parseFloat(row?.amountOfDone ?? 0),
            comment: row?.comment,
          },
          res,
          rej,
        });
        setSelectedItem(row);
        return setIsAmountOfDoneModalOpened(true);
      }

      await put(`/tasks/${row?.id}/status`, {
        status: newStatus,
        amountOfDone: parseFloat(row?.amountOfDone ?? 0),
        comment: row?.comment,
      });
      res();
    });
  };

  const handleAmountOfDoneSubmit = async ({
    amountOfDone,
    amountToMake,
    comment,
  }) => {
    if (typeof amountOfDone === 'number' && !isNaN(amountOfDone)) {
      if (pendingStatusChange) {
        await put(pendingStatusChange.url, {
          ...pendingStatusChange.payload,
          amountOfDone,
        });
      }

      await put(`/tasks/${selectedItem?.id}/amounts`, {
        amountToMake,
        amountOfDone,
        comment,
      });

      toast.success(
        t('$*cateringsFlow.amountOfDoneHasBeenChangedSuccessfully')
      );

      pendingStatusChange?.res();
      setRefresh(!refresh);
      closeAmountOfDoneModal();
    }
  };

  const closeAmountOfDoneModal = () => {
    pendingStatusChange?.rej();
    setSelectedItem({});
    setPendingStatusChange(null);
    setIsAmountOfDoneModalOpened(false);
  };

  return (
    <>
      <CardWrapper
        title={t(
          '$*cateringsFlow.bagsPackhouse.dietsList.tableTitle',
          '$$Diety do spakowania'
        )}
      >
        <CardBody>
          <GridContainer>
            <GridItem md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      setShowChoices(!showChoices);
                    }}
                    checked={showChoices}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t(
                      '$*cateringsFlow.filter.showChoices',
                      'Pokazuj "wybory"'
                    )}
                  </div>
                }
              />
            </GridItem>
            <GridItem md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      setShowChoicesAndDiets(!showChoicesAndDiets);
                    }}
                    checked={showChoicesAndDiets}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={
                  <div style={{ display: 'flex' }}>
                    {t(
                      '$*cateringsFlow.filter.showChoicesAndDiets',
                      'Pokazuj "diety standardowe"'
                    )}
                  </div>
                }
              />
            </GridItem>
          </GridContainer>
          <DataGrid
            key={`_${showChoices ? 1 : 0}_${showChoicesAndDiets ? 1 : 0}}`}
            refresh={refresh}
            actions={true}
            customActions={customActions}
            showColumnFilter={true}
            columns={columnConfig({
              t,
              employees,
              onStatusChangeAction,
              onEmployeeChangeAction,
            })}
            edit={false}
            paginationBottom={false}
            paginationTop={true}
            refreshOnFetch
            remove={false}
            getTrProps={(state, rowInfo, column) => {
              if (rowInfo === undefined) {
                return {};
              }

              return {
                'data-qnt': isEmpty(rowInfo?.original?.requires) ? 0 : 1,
              };
            }}
            getTrGroupProps={(_, rowInfo) => ({
              style: {
                background:
                  STATUS_COLORS[rowInfo?.original?.status]?.rowBackground,
                borderBottom: '2px solid darkgrey',
                padding: '8px 0',
              },
            })}
            defaultExpanded
            SubComponent={row =>
              !isEmpty(row) && !isEmpty(row?.original?.requires) ? (
                <SubTasksList
                  row={row}
                  data={row?.original?.requires ?? []}
                  columns={subTasksColumnConfig({ t })}
                />
              ) : null
            }
            url={`/pack-diet-tasks?taskList.id=${tasksListId}`}
            manipulateQuery={(requestData, query) => {
              const rangeFilters = ['amountToMake', 'amountOfDone'];
              rangeFilters.forEach(el => {
                if (query.hasOwnProperty(el)) {
                  const filters = query[el];
                  delete query[el];
                  if (filters.valueFrom) {
                    query[el + '[gte]'] = filters.valueFrom;
                  }
                  if (filters.valueTo) {
                    query[el + '[lte]'] = filters.valueTo;
                  }
                }
              });

              if (showChoices && showChoicesAndDiets) {
                // no filters
              } else if (!showChoices && showChoicesAndDiets) {
                query['key[]'] = 'PB-C';
                query['key[_operator]'] = 'nlike';
              } else if (showChoices && !showChoicesAndDiets) {
                query.key = 'PB-C';
              } else {
                query['key[]'] = 'PB,PB-C';
              }

              return query;
            }}
            defaultHiddenColumns={['id', 'diff', 'note']}
            striped={false}
          />
        </CardBody>
      </CardWrapper>

      <AmountOfDoneModal
        isOpened={isAmountOfDoneModalOpened}
        initialComment={selectedItem?.comment}
        name={
          selectedItem?.dish?.nameForClient
            ? `(${selectedItem?.dish?.id}) ${selectedItem?.dish?.nameForClient}`
            : selectedItem?.name
        }
        allowChangeAmountToMake={false}
        initialAmountToMake={selectedItem?.amountToMake}
        initialAmountOfDone={selectedItem?.amountOfDone}
        amountToMakeAfterRefreshedAmounts={
          selectedItem?.amountToMakeAfterRefreshedAmounts
        }
        closeModal={() => {
          setSelectedItem({});
          setIsAmountOfDoneModalOpened(false);
        }}
        handleSubmit={handleAmountOfDoneSubmit}
      />
      <PdfDialog
        fileUrl={selectedItem?.['@id']}
        isOpened={isPDFPreviewOpened}
        closeModal={() => {
          setSelectedItem({});
          setIsPDFPreviewOpened(false);
        }}
      />
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withStyles(combinedStyles)(BagsPackhouseTasksList);
